
export default {
  emptyData: "No Anomalies Seen",
  loading: "Loading",
  export: "Export",
  // FilterView
  filterViewBusinessRelevancy: "Business Relevancy",
  filterViewTrafficeClass: "Traffic Class",
  filterViewCategory: "Category",
  filterViewAllDetails: "View all details",

  // ApplicationTableView
  applicationDashboardNoData: "No Data Available",
  applicationTableViewClass: "Class",
  applicationTableViewAll: "All",
  applicationTableViewName: "Name",
  applicationTableViewQoEChange: "QoE change",
  applicationTableViewQoE: "QoE",
  applicationTableViewSiteCount: "Site count",
  applicationTableViewCircuitCount: "Circuit count",
  applicationTableViewSiteTraffic: "App class",
  applicationTableViewAppFamily: "App family",
  applicationTableViewUsage: "Usage",
  applicationTableViewLoss: "Packet loss (%)",
  applicationTableViewLatency: "Latency (ms)",
  applicationTableViewJitter: "Jitter (ms)",
  applicationTableViewApplication: "Applications",
  applicationTableTitleCustom: "Custom list of applications",
  applicationTableCountText: " applications",
  applicationTableSelectedText: " selected:",
  appDashboardQOEGoodValue: "(QoE 8 - 10)",
  appDashboardQOEFairValue: "(QoE 5 - <8)",
  appDashboardQOEPoorValue: "(QoE 0 - <5)",
  appDashboardQOEGoodLabel: "Good",
  appDashboardQOEFairLabel: "Fair",
  appDashboardQOEPoorLabel: "Poor",
  // ExampleDashlet2
  exampleDashlet2QoE: "QoE Distribution by App Classes",
  exampleDashlet2SLA: "SLA Class 2 has the most poor apps.",

  // DashletNotification
  dashletNotificationHighlights: "Highlights",
  dashletNotification8App:
    "8 apps were added. The number of poor apps increased by 12%. Total bandwidth increased by 20%.",
  dashletNotification5NewApp: "5 new apps were configured as SLA Class 2",
  dashletNotification2NewSites:
    "2 new sites were deployed. The number of poor sites increased by 12%. Total bandwidth increased by 20%.",
  dashletNotificationAfterPolicy:
    "After policy config change, QoS for Youtube has improved for 5 sites including San Jose, San Francisco and Boston.",

  // QoeDistributionView
  qoeDistributionViewQoE: "QoE Distribution by App Classes",
  qoeDistributionViewSLA: "SLA {object} has the most poor apps",
  qoeDistributionViewTooltip:
    "QoE distribution across applications grouped together by their SLA needs",
  qoeDistributionViewAppCount: "App Count",
  app360TitleIcon:
    "Application QoE is measure of deviation of network KPI's from application thresholds.",

  // TrendingAppsView
  trendingAppsViewQoE: "QoE",
  trendingAppsViewBandwidth: "Usage",
  trendingAppsViewLoss: "Loss",
  trendingAppsViewLatency: "Latency",
  trendingAppsViewJitter: "Jitter",
  trendingAppsViewApplication: "Application Trend Analysis",
  trendingAppsViewTrending: "Trending Applications",
  trendingAppsViewTrendAnalysis: "Trend analysis",
  trendingAppsViewMostDrop: "Most Avg. Drop of ",
  trendingAppsViewDrop: "Drop",
  trendingAppsViewTrend: "Trend",
  trendingAppsViewMostRise: "Most Avg. Rise of ",
  trendingAppsViewRise: "Rise",
  trendingAppsPrevious: "Previous",
  trendingAppsCurrent: "Current",
  trendingAppsChange: "Change",
  trendingAppsTooltip:
    "The trending applications are the applications that have most rise or drop of certain KPI values, comparing the average between the selected time period and the previous equivalent time period.",

  // Overview Summary
  overviewSummaryTooltip:
    "The average number of {tooltip} {title} is {currentValue}% in last {timeLabel}. {changeText} comparing to the average number for {prevTimeLabel}",
  avgAvailabilitySummary: "The average site {tooltip} is {currentValue}% over the last {timeLabel}. {changeText} in the average availability when compared to {prevTimeLabel}",
  availabilitySummaryIncreaseLabel: "There is a {changeValue}% increase",
  availabilitySummaryDecreaseLabel: "There is a {changeValue}% decrease",
  deviceSummary: `The average number of {tooltip} {title} is {currentValue} in last {timeLabel}. {changeText} comparing to the average number for {prevTimeLabel}`,

  usageSummary:
    "The aggregate usage of site is {currentValue} in last {timeLabel}. {changeText} comparing to the aggregate usage for {prevTimeLabel}",
  summaryIncreaseLabel: "This increases",
  summaryDecreaseLabel: "This decreases",
  summarynoChangeLabel: "There is no change",
  summarysubTitleAvailability: "with High Availability",
  summaryTitleSites: "Sites",
  summarysubTitleQoe: "with Good QoE",
  summaryTooltipAvail: "high availability",
  summaryTooltipQoe: "good QoE",
  summarySubTitleTooltipAvail:
    "Site availability is the percentage uptime of sites for the selected time period",
  summarysubTitleToolTipQoe:
    "Application QoE is a measure of user experience  assessed by comparing application thresholds against network KPIs. Percentage value does not include unknown(No QoE) applications",
  summarysubTitleToolTipAvailability:
    "Circuit availability is the percentage uptime of circuits for the selected time period",
  summaryDeviceSubTitleTooltipAvail:
    "Device availability is the percentage uptime of devices for the selected time period",
  singleSiteSummarySubtitle: "Availability",
  singleSiteSummaryTooltip: "availability",
  singleSiteAvailabilitySummarySubtitleTooltip:
    "Percentage uptime of this site for the selected time period.",
  singleSiteUsageSummarySubtitleTooltip:
    "Aggregate site usage for the selected time period",
  summaryNoChange: "No change",

  // AppView
  appViewApplication: "Applications",
  appViewApplicationCap: "APPLICATIONS",
  summaryTitleApplications: "Applications",
  appViewAll: "All",
  appViewBusinessRelevant: "Business Relevant",
  appViewBy: "View by: ",
  appViewByQoE: "QoE",
  appViewByGoodQoE: "Good QoE",
  appViewByFairQoE: "Fair QoE",
  appViewByPoorQoE: "Poor QoE",
  appViewByViewDetails: "View Details",
  tableHeadingTopApp: "Bottom applications by QoE",
  appQoeDistribution: "Distribution by QoE",
  circuitQoeDistribution: "Distribution by QoE",
  appQoeTooltipTitle: "Application QoE",
  appQoeTooltipDefinition:
    "is measure of deviation of network KPI's from application thresholds.",
  appQoeUnknownTooltip: "is not available for this application.",
  appQoeDonutGood: "Good (QoE 8 - 10)",
  appQoeDonutFair: "Fair (QoE 5 - <8)",
  appQoeDonutPoor: "Poor (QoE 0 - <5)",
  appQoeDGray: "Unknown (No QoE)",
  appDataNotAvailable: "Applications data not available",
  appQoeSiteCount: "Site",

  // CircuitView
  circuitViewCircuits: "Circuits",
  circuitViewCircuitsCaps: "CIRCUITS",
  summaryTitleCircuits: "Circuits",
  circuitViewAvailability: "Avail",
  circuitViewUtilization: "Utilization",
  circuitViewBy: "View by: ",
  circuitDataNotAvailable: "Circuits data not available",
  circuitTableAvailability: "Bottom Available Circuits (TLOC)",
  circuitTableUtilization: "Top Utilized Circuits",
  circuitAvailBarChart: "Circuit availability",
  circuitAvailBarChartDefinition:
    "is percentage of uptime for the selected time period",
  circuitViewDetails: "View Details",
  circuitViewBandwidthForecast: "View Bandwidth Forecast",
  circuitViewTotalTime: "Total Availability",
  sitesViewTotalTime: "Total Availability",
  circuitQoeSiteCount: "Circuit",


  // CircuitsFilterView
  filterViewBandwidth: "BANDWIDTH",
  filterViewAvailability: "AVAILABILITY",
  filterViewRxTx: "RX + TX",

  // DeviceView
  clientsTitle: "Clients",
  deviceUsage: "Usage",
  deviceUsageTooltip: "usage",
  deviceViewBy: "View by: ",
  UserTableHeading: "Top Users",
  topClients: "Top available clients",
  topClient: "Top clients",

  // Clients dashlet
  clientIpColHeader: "Client IP",
  userDataUsage: "Data usage",
  clientsTopAppsColHeader: "Top used apps",
  clientSitesColHeader: "Sites",
  clientDistributionColHeader: "Distribution by usage",
  usersDataNotAvailable: "Users data not available",

  // DashletSiteView
  dashletSiteViewAvailability: "Avail",
  dashletSiteViewSites: "Sites",
  dashletSiteViewSitesCap: "SITES",
  dashletSiteViewSitesWith: "Summary usage",
  dashletSiteViewAvailabilityWith: "Summary",
  dashletSiteViewUtilization: "Utilization",
  dashletSiteViewBy: "View by: ",
  dashletSiteViewDetails: "View Details",
  siteDataNotAvailable: "Sites data not available",
  siteTableAvailability: "Bottom available sites",
  siteTableUtilization: "Top Utilized Sites",
  siteTableAvailBarChart: "Site availability",
  siteTableAvailBarChartDefinition:
    "is percentage of uptime for the selected time period",
  siteViewLocation: "Location",

  // OverallTrendView
  overallTrendViewAppCount: "App count by QoE",
  overallTrendViewGood: "Good (QoE 8 - 10)",
  overallTrendViewFair: "Fair (QoE 5 - <8)",
  overallTrendViewPoor: "Poor (QoE 0 - <5)",
  overallTrendViewTotal: "Total usage (bytes)",
  overallTrendViewBandwidth: "Usage",
  overallTrendViewOverall: "Overall Trend",

  // SelectedAppView
  selectedAppViewQoE: "QoE of the selected applications",
  selectedAppViewBandwidth: "Usage of the selected applications",

  // TrendAnalysis
  trendAnalysisApplication: "Trend by application",
  trendAnalysisTop: "Top 5 apps by bandwidth",
  trendAnalysisCustomList: "Custom list",

  // overview folder
  applicationDonutLabelGoodAvailable: "Good (95% - 100%)",
  applicationDonutLabelFairAvailable: "Fair (80% - <95%)",
  applicationDonutLabelPoorAvailable: "Poor (<80%)",
  applicationDonutLabelNAAvailable: "Unknown",
  applicationDonutTitleUtilizationGood: ">90% Utilization",
  applicationDonutTitleUtilizationFair: "60% - 90% Utilization",
  applicationDonutTitleUtilizationPoor: "<60% Utilization",
  applicationCircuitViewLabelAvailable: "Available",
  applicationOverviewLabelAvailabilityOn: "Availability in",
  applicationCircuitViewLabelPrevious: "Previous",
  applicationOverviewUsed: "Used",
  applicationOverviewTotal: "Total time",
  applicationOverviewLabelGood: "Good",
  applicationOverviewLabelFair: "Fair",
  applicationOverviewLabelPoor: "Poor",
  applicationOverviewLabelNotAvailable: "Unknown",
  applicationOverviewLabelGray: "Unknown (No QoE)",
  applicationOverviewLabelUsage: "Usage",
  applicationOverviewLabelUsageIn: "Usage in",
  applicationOverviewLabelLocal: "Local",
  applicationOverviewLabelRemote: "Remote",
  applicationOverviewLabelSummary: "Summary",
  applicationOverviewLabelSummaryCap: "SUMMARY",
  applicationOverviewLabelBandwidthMbps: "Bandwidth",
  applicationOverviewLabelDevices: "Devices",
  applicationOverviewLabelDevicesCaps: "DEVICES",
  applicationOverviewLabelDevicesWith: "Devices with high availability",
  applicationOverviewLabelTopDevices: "Top Devices",
  applicationOverviewLabelTunnelName: "Tunnel Name",
  applicationOverviewLabelDataUsage: "Data usage",
  applicationExperienceUsage: "Usage",

  // App360TabView
  app360TabViewSites: "Sites",
  app360TabViewAppFlow: "App flow",
  app360TabViewTopUsers: "Top Users",
  app360TabViewTopClients: "Top clients",
  app360TabViewPathScore: "Path Score",
  app360TabViewPartnerDataExchange: "Partner Data Exchange",
  app360TabViewEdgeDataExchange: "Edge Data Exchange",
  app360MapView: "Map view",
  app360QoeSummary: "QoE summary",
  app360SiteList: "Site list",
  app360TotalSites: "Total sites using this app",
  appp360QoeTopSites: "Top Sites by QoE",
  app360Overview: "Overview",
  app360High: "GOOD",
  app360Fair: "FAIR",
  app360Low: "LOW",
  app360Unknown: "UNKNOWN",
  app360Experience: "EXPERIENCE",

  // App360PathScoreTable
  app360PathScoreColumnPublishedDate: "Published Date",
  app360PathScoreColumnSiteID: "Site ID",
  app360PathScoreColumnSystemIP: "System IP",
  app360PathScoreColumnHostname: "Hostname",
  app360PathScoreColumnInterface: "Interface",
  app360PathScoreColumnService: "Service",
  app360PathScoreColumnReceived: "MSFT (Received)",
  app360PathScoreColumnComputed: "SDWAN (Computed)",
  app360PathScoreColumnMicrosoft: "Microsoft",
  app360PathScoreColumnNetflow: "Netflow",
  app360PathScoreColumnCloudExpress: "Cloud Express (CXP)",
  app360PathScoreComplexColumnScore: "Score",
  app360PathScoreComplexColumnLatency: "Latency Anomaly",
  app360PathScoreCND: "Client network delay (CND)",
  app360PathScoreSND: "Server network delay (SND)",
  app360PathScoreACE: "Art Cloud Express Anomaly",

  // App360PartnerDataExchange
  partnerDataExchangeColumnDate: "Date",
  partnerDataExchangeColumnDirection: "Direction",
  partnerDataExchangeColumnStatus: "Status",
  partnerDataExchangeColumnMessage: "Message",
  partnerDataExchangeColumnFileName: "File Name",

  // App360Table
  site: "Site",
  tunnels: "Tunnels",
  tunnelsData: "Tunnels Data",
  app360host: "Host",
  app360sites: "Sites",
  app360Color: "Color",
  app360Latency: "Latency (ms)",
  app360LatencyForReports: "Latency <br> (ms)",
  app360Loss: "Loss (%)",
  app360LossForReports: "Loss <br> (%)",
  app360Jitter: "Jitter (ms)",
  app360JitterForReports: "Jitter <br> (ms)",
  userIP: "Client IP",
  qoe: "QoE",
  qoeScore: "QoE score",
  usageVolume: "Usage Volume (GB)",
  top10Users: "Top 10 clients",
  reportApp360Top10ClientsLabel: "Top 10 clients",

  // ApplicationExperienceVew
  appExperienceApplication: "Application Experience",
  appExperienceAppType: "Application Type:",
  appExperienceQoE: "QoE:",
  appExperienceAll: "All",
  appExperienceByUsage: "Top 20 by usage",
  appExperienceTop: "Usage (Top 20)",
  appExperienceUSAGE: "Usage (Total)",
  appExperienceQOE: "QoE",
  appExperienceQualityEperience: "Quality of Experience",
  appExperiencePoor: "Poor",
  appExperienceGood: "Good",
  appExperienceFair: "Fair",
  appExperienceGray: "Unknown (No QoE)",
  appExperienceUsage: "Usage",
  appExperiencePacket: "Packet loss",
  appExperienceLatency: "Latency",
  appExperienceJitter: "Jitter",
  appExperienceSite: "Site (QoE by Site)",
  appExperienceCircuit: "Circuit (QoE by Circuit)",
  appExperienceOut: "out of all apps", // 20% out of all apps
  appExperienceOutOf10: " out of 10", // 1 out of 10
  appExperienceGoodQoE: "Good (QoE 8 - 10)",
  appExperienceFairQoE: "Fair (QoE 5 - <8)",
  appExperiencePoorQoE: "Poor (QoE 0 - <5)",
  appExperienceNoData: "No Data Available",
  appExperienceTrend: "Application Trend Analysis",
  appExperienceGoodSemi: "Good:",
  appExperiencePoorSemi: "Poor:",
  appExperienceFairSemi: "Fair:",
  appExperienceNoQoESemi: "Unknown:",
  appExperienceGoodLabel: "Good",
  appExperienceFairLabel: "Fair",
  appExperiencePoorLabel: "Poor",
  appExperienceUnknownLabel: "Unknown",
  appExperienceGoodValue: "(QoE 8 - 10)",
  appExperienceFairValue: "(QoE 5 - <8)",
  appExperiencePoorValue: "(QoE 0 - <5)",
  appExperienceUnknownValue: "(No QoE)",
  appExperienceApplications: "Applications",
  // all application ribbon
  appGroupWidget: {
    defaultPath: "Current path: ",
    recomPath: "Recommended path: ",
    pathQuality: "Path quality",
    noRecom: "No recommendations",
    recommendations: "Path recommendations",
    recom30Days: "Powered by ThousandEyes WAN Insights",
    hourlyUsers: "Hourly active clients in the last 30 days",
    avgQuality:
      "Average quality observed on the default load balancing in the past 30 days",
    forcastQuality: "Forcasted quality if all the recommendations are applied",
    expectedImpact: "Quality improvement * the number of hourly active clients",
    over: "Over",
    users: "users",
    viewRecom: "View Recommendations"
  },

  // All sites page
  siteNameLabel: "Site name",
  sites: "Sites",
  hostLocation: "Host location",
  hostLocationForReports: "Host <br> location",
  hostTunnelName: "Host tunnel name",
  appflowQoeScore: "QoE score",
  appflowQoeScoreForReports: "QoE <br> score",
  app360AppFlowLocalDevice: "Local device",
  app360AppFlowLocalDeviceForReports: "Local <br> device",
  app360AppFlowRemoteDevice: "Remote device",
  app360AppFlowRemoteDeviceForReports: "Remote <br> device",
  app360AppFlowLocalColor: "Local color",
  app360AppFlowLocalColorForReports: "Local <br> color",
  app360AppFlowRemoteColor: "Remote color",
  app360AppFlowRemoteColorForReports: "Remote <br> color",
  siteLocation: "Location",
  app360siteIDLabel: "Sites",
  siteAvailabilityPercentage: "Availability (%)",
  qoeStatus: "QoE Status",
  allSiteUsersLabel: "Clients",
  latencyInMPS: "Latency (ms)",
  jitterInMS: "Jitter (ms)",
  allSiteMapLabel: "Map",
  allSiteListLabel: "List",
  allSiteSitesLabel: "All sites",
  singleSitePathTableLabel: "Path quality by device pair",
  noData: "No Data",
  allSiteAvailabilityLabel: "Availability",
  allSiteAllLabel: "All",
  allSiteHighLabel: "High",
  allSiteFairLabel: "Fair",
  allSiteLowLabel: "Low",
  allSiteUnknownLabel: "Unknown",
  appGroupPerSiteByWorst: "View by: Worst Experience",
  allSiteSiteLabel: "Site",
  allSiteLocationLabel: "Location",
  allSiteUsageLabel: "Usage",
  allSitesAllSitesLabel: "Total sites ",
  allSitesLegend: "Legend",
  allSiteQoELabel: "QoE",
  allSitesView: {
    availability: "Availability",
    pathRecommendations: "Path recommendations",
    sites: "Sites: ",
    ready: "Ready",
    applied: "Applied",
    norecommend: "None",
    all: "All",
    totalSites: "TOTAL SITES: ",
    siteTipLabel: "Site: ",
    siteIDLabel: "Site ",
    noRecom: "- No recommendations -",
    application: "Application",
    defaultPath: "Current path",
    recoState: "State",
    recoStateType: "Ready",
    topSites: "Top Sites",
    recoSitesLabel: "Sites",
    activeUsersLabel: "Active clients",
    recoStateLabel: "Action State",
    noSitesOnMap: "No sites available on the map",
    pathRecommd: "Path recommendations",
    noActionCards: "No action cards available"
  },

  // app360
  syncronizedChartQoETitle: "Quality of Experience (QoE)",
  syncronizedChartQoE: "QoE score",
  syncronizedChartBandwidth: "Usage",
  syncronizedChartLoss: "Packet loss",
  syncronizedChartLatency: "Latency",
  syncronizedChartJitter: "Jitter",
  syncronizedChartNetworkLatency: "Network Latency",
  syncronizedChartAverage: "Average",
  syncronizedChartCircuits: "Circuits",
  syncronizedChartAppMetrics: "App metrics",
  syncronizedChartAppFlow: "App flow",
  syncronizedChartAppUsers: "App Users",
  syncronizedChartTopClients: "Top clients",
  syncronizedChartUnit: "ms",
  syncronizedChartNoData: "No Data Available",
  app360AllLabel: "All",
  app360GoodLabel: "Good",
  app360PoorLabel: "Poor",
  app360FairLabel: "Fair",
  app360UnknownLabel: "Unknown",
  app360MapLabel: "Map",
  app360ListLabel: "List",

  // dashboardConfig
  dashboardConfigSite: "Analytics · Sites",
  granularityView: "App Metrics Granular View",
  dashboardAllApplicationLabel: "Analytics · Applications",
  dashboardInsightsLabel: "Analytics · Predictive Networks",
  dashboardSecurityLabel: "Analytics · Security",
  dashboardThreatIntelligenceLabel: "Analytics · Threat Intelligence",

  // worklfow folder
  workflowSummary: "Summary",
  worklfowAlmost: "Almost there! Please find below the summary of the Report.",
  worklfowScope: "Scope",
  worklfowEdit: "Edit",
  worklfowSubmit: "Submit",
  worklfowSchedule: "Scheduler",
  worklfowRecurring: "Recurring",
  worklfowEmail: "Email and Notification",
  worklfowReport: "Report Dashboard",
  worklfowEnterSite: "Enter custom site",
  worklfowEnterApp: "Enter custom application",
  worklfowSetUp: "Setup Report Scope",
  worklfowName: "Name the report and select the scope to include in Report",
  worklfowSite: "Site",
  worklfowAllSites: "All Sites",
  worklfowAllCustomSite: "Select Site",
  worklfowApplication: "Application",
  worklfowAllApp: "All Applications",
  worklfowCustomApp: "Select Application",
  worklfowDelivery: "Delivery and Notification",
  worklfowEmailReport: "Email Report",
  worklfowAsLink: "As a Link (Default)",
  worklfowEmailRecipient: "Email recipient (limit 5 emails)",
  worklfowChanges: "Changes will be lost. Are you sure you want to continue?",
  worklfowCancel: "Cancel",
  worklfowExit: "Exit",
  workflowExiting: "Exiting Workflow",
  worklfowNext: "Next",
  worklfowPrevious: "Previous",
  workflowAsAttachment: "As an Attachment",

  // Select File Type
  workflowSelectFileType: "Select File Type",
  workflowSelectFileHint: "Select the file type that you want to generate",
  workflowSelectFilePdf: "PDF",
  workflowSelectFileCsv: "CSV",
  workflowFile: "File Type",
  workflowFileType: "Type",

  worklfowScheduleReport: "Schedule Report",
  worklfowTimeRange: "Time Range",
  worklfowLastWeek: "Last Week",
  worklfowLastMonth: "Last Month",
  worklfowReccuring: "Run Recurring",
  worklfowRunNow: "Run Now",
  worklfowDaily: "Last 24 hours",
  worklfowAppllication: "Appllication",
  worklfowType: "Type",
  worklfowDetails: "Details",
  worklfowEmailTo: "Email to:",
  workflowGenerated: "Generated the {object1} report ({object2} data only)",
  workflowEmail: "Email",
  workflowStart: "Start time",
  workflowRepeats: "Repeats",
  workflowMonthly: "Monthly",
  workflowWeekly: "Weekly",
  workflowDaily: "Daily",
  workflowOneTimeNow: "One-Time (Now)",
  workflowOneTime: "One-Time",
  workflowEmptyReportNameErrorRegexName:
    "Use letters, numbers, underscores, hyphens and periods.",
  workflowReportNameLabel: "Report Name",
  workflowDeliveryDocumentKey: "Document Passkey",
  workflowSuccessMessage: "Successfully copied.",
  duplicateEmailMsg: "Duplicate email not allowed.",

  // Reports table
  reporTableColReportNameLabel: "Report Name",
  reporTableColScheduleLabel: "Schedule",
  reporTableColLastRunLabel: "Last Run",
  reporTableColCountLabel: "Reports",
  reporTableColDeliveryLabel: "Delivery",
  reportTemplate: "Report Template",
  reporTableActions: "Actions",
  reporTableEdit: "Edit",
  reporTableColDocKeyLabel: "Document Passkey",
  reporTableEmailLabel: "Email",

  // Reports tabs
  reportGeneratedReports: "Generated Reports",
  reportReportTemplates: "Report Templates",
  reporTableCancel: "Cancel",
  reporTableDelete: "Delete",
  reportDeleteAlertMsg: "Are you sure you want to delete this report?",

  // Executive Summary
  scopeHeading: "Scope",
  scopeDetails: "All sites, All application",
  applicationsHeading: "Application",
  allApplications: "Application Summary",
  appExperience: "Applicaton Experience",
  sitesOverview: "Sites Overview",
  sitesOnMap: "Sites on Map",
  top50Sites: "Top 50 Sites",
  totalSitesLabel: "Total Sites",
  viewByWorstExperience: "View by: Worst Experience",
  qoeInfo:
    "[1] - Application QoE is measure of deviation of network KPIs from application thresholds.",
  siteAvailabilityInfo:
    "[2] - Site Availability is percentage of uptime for the selected time period.",
  circuitAvailabilityInfo:
    "[3] - Circuit Availability is percentage of uptime for the selected time period.",
  dataUsage: "Data Usage",
  siteSummary: "Site Summary",
  overallSummary: "Overall Summary",
  cicuitSummary: "Circuit Summary",
  applicationSummary: "Application Summary",
  summaryTrend: "Trend(+ /-): compared average value with the ",
  siteTrend: "Compared with: the ",
  siteAvailabilityBreakdown: "Site Availability Breakdown [2]",
  circuitAvailabilityBreakdown: "Circuit Summary Breakdown [3]",
  applicationQoeBreakdown: "Application QoE BreakDown [1]",
  topAvailableSites: "Top available sites",
  topAvailableCircuits: "Top available Circuits (TLOC)",
  tableHeadingBottom: "Top applications by QoE",
  workflowErrorSubmitting:
    "An error occurred while trying to submit the report.",
  workflowSubmitAgain: "Submit again",
  workflowSubmitting: "Submitting a report...",
  workflowNext:
    "An error occurred while trying to validate the report name. Report name should be unique. Update report name.",
  workflowValidateAgain: "Validate again.",
  workflowValidateReport: "Validating a report name...",
  reportHeadingApplicationOverview: "Application Overview",
  reportHeadingQoEDistribution: "QoE distribution by app classes",
  trendingApplications: "Trending Applications",
  top50Applications: "Top 50 Applications by Usage",
  reportCopyTextTrendingApplications: `The tredning applications are the applications that have most rise or drop of certain KPI values,
  comparing the average between the selected time period and the previous equivalent time period.`,
  reportMostAvgDropQoE: "Most Avg. Drop of QoE",
  reportMostAvgRiseUsage: "Most Avg. Rise of Usage",
  bottomAvailableCircuits: "Bottom available circuits (TLOC)",
  circuitsLabel: "Circuits",
  sitesLabel: "Sites",
  appsLabel: "Applications",

  // Report app 360
  reportApp360TopAppFlowLabel: "Top 50 apps flow",
  reportApp360Top10UsersLabel: "Top 10 clients",
  reportApp360Top50SitesList: "Top 50 sites list",

  // ReportTemplates
  reportTemplatesSummary: "Executive Summary",
  reportTemplatesSites: "All Sites",
  reportTemplatesApps: "All Applications",
  reportTemplatesAppSum: "Application Summary",
  reportTemplatesCircuit: "Circuit Summary",
  reportTemplatesSite: "Site Summary",
  reportTemplatesExperience: "Application Experience",
  reportTemplatesTrends: "Application Trends",
  reportTemplatesAvailability: "Site Availability",
  reportTemplatesUtilization: "Site Utilization",
  reportTemplatesGenerate: "Generate",

  // Accounts config
  accountsOverlay: "Fabric",
  accountsOverlayStatus: "Analytics Status",
  accountsOverlayDefined: "IDP Server",
  accountsOverlayAction: "Actions",

  // Accounts Filter Options
  allAccounts: "All",
  newAccounts: "New",
  activatedAccounts: "Activated",
  notActivatedAccounts: "Not Activated",
  showOverlays: "Show Fabrics",
  accounts: "Accounts",

  apply: "Apply",
  tunnel: "Tunnel",
  vpn: "Vpn",
  tunnelFilter: "Filter tunnel names",
  vpnFilter: "Filter Vpn Id's",
  applicationLabel: "Application",
  networkLabel: "Network",
  highAvailability: "High Availability",
  fairAvailablity: "Fair Availability",
  lowAvailablity: "Low Availability",
  unknownAvailablity: "Unknown Availability",
  sitesHigh: "High",
  sitesFair: "Fair",
  sitesLow: "Low",
  sitesUnknown: "Unknown",
  sitesPoor: "Poor",
  sitesGood: "Good",
  smartAccount: "Smart Accounts",
  smartAccountSupportRole: "Support User Accounts",
  smartAccountPlaceHolder: "Search accounts",
  smartAccountSearch: "Search Fabrics",
  smartAccountNone: "No such accounts",
  missingAvailableFeaturesFlag:
    "Something unexpected happened. Please select a Fabric again.",
  siteHoverName: "Name",
  siteHoverId: "Id",
  app30SiteName: "Site",
  geoMapHigh: "High",
  geoMapFair: "Fair",
  geoMapLow: "Low",
  geoMapAvailability: "Availability",
  geoMapUsage: "Usage",
  geoMapLocation: "Location",
  geoMapSitesLabel: "Sites",
  geoMapQoE: "QoE",

  // IDP config
  defineIdp: "Define IDP",
  viewIdp: "View IDP",
  editIdp: "Edit IDP",
  deleteIdp: "Delete IDP",
  idpConfigNoDataAvailable: "No Data Available",
  idpConfigFieldRequired: "This field is required",
  idpConfigCancel: "Cancel",
  idpConfigSave: "Save",
  idpConfigInvalidXml: "Not a valid xml file",
  idpConfigInvalidDomains: "Invalid domain identifier",
  idpMetadata: "IDP Metadata",
  samlMetadata: "SAML Metadata",
  downloadIdpMetadata: "Download IDP Metadata",
  idpConfigBrowseFile: "Browse File",
  idpConfigIdpDefined: "IDP is Defined",
  idpConfigIdpNotDefined: "IDP is Not Defined",
  idpConfigDeleteIdpPromptMessage: "Do you really want to delete IDP?",
  idpConfigDeleteSuccessMessage: "Idp deleted successfully",
  activate: "Activate",
  deActivate: "Deactivate",
  idpConfigActivateOverlayPromptMessage:
    "Do you really want to activate Fabric?",
  idpConfigDeactivateOverlayPromptMessage:
    "Do you really want to deactivate Fabric?",
  idpConfigUpdateSuccessMessage: "Fabric updated successfully",

  // app360 path anayltics
  app360PathAnalytics: {
    appScore: "App score",
    sdWanAppScore: "SDWAN app score (computed)",
    msftAppScore: "MSFT app score (received)",
    appScoreStatusOk: "OK",
    appScoreStatusInit: "INIT",
    appScoreStatusNotOk: "Not OK",
    appScoreQoeStatusGood: "Good",
    appScoreQoeStatusDegraged: "Degraded",
    appScoreQoeStatusBad: "Bad",
    appScoreQoeStatusNoOpinion: "No-opinion",
    scoreGood: "Score > 50",
    scoreFair: "Score <= 50",
    usage: "Usage",
    networkTelemetry: "Network telemetry",
    microsoftTelemetry: "Microsoft telemetry",
    networkDelay: "Network delay",
    pathAnalytics: "Path analytics",
    systemIP: "System IP",
    siteId: "Site ID",
    hostName: "Host name",
    publicIP: "Public IP",
    path: "Path",
    interface: "Interface",
    dataComparison: "Data comparison",
    deviceName: "Device name",
    avearage: "Average",
    latency: "Latency",
    loss: "Loss",
    jitter: "Jitter",
    serviceArea: "Service area",
    serverRegion: "Server region",
    ms: "ms",
    cancel: "Cancel",
    filter: "Filter",
    threshold: "Threshold",
    mediaType: "Media Type",
    transportType: "Transport Type",
    appPathDataNoAvailable: "No Data Available"
  },
  // insights page
  insightsDashboardLabel: "Recommendation Dashboard",
  appGroupPerSiteSidePanelTitle: "Path recommendation",

  appGroupPerSite: {
    availability: "Availability",
    recommendation: "Recommendation",
    sites: "Sites: ",
    ready: "Ready",
    applied: "Applied",
    totalSites: "TOTAL SITES: ",
    application: "Application",
    defaultPath: "Current path",
    recoState: "State",
    recoStateType: "Ready",
    topSites: "Top Sites",
    recoSitesLabel: "Sites",
    recoSiteLabel: "Site",
    recoPathLocationLabel: "Location",
    activeUsersLabel: "Active clients",
    recoExpecteImpactLabel: "Expected impact",
    recoPathTotalNumUsersLabel: "Active clients",
    recoStateLabel: "State",
    recoActionLabel: "Action",
    recoImpactedUsers: "Impacted clients",
    recoExpecteGainLabel: " % Gain",
    localSiteLabel: "Local site ID",
    localDeviceLabel: "Local device",
    remoteDeviceLabel: "Remote device",
    remoteSiteLabel: "Remote site ID",
    userExpChartSiteLabel: "SITE",
    userExpChartCurrentPathLabel: "Current path quality",
    userExpChartRecomLabel: "Recommended path quality",
    userExpChartActiveUsrLabel: "Active clients",
    userExpChartFromLabel: "From:",
    userExpChartToLabel: "To:",
    cardActionSwitchLabel: "Switch from",
    cardActionToLabel: "to",
    pathListTable: "Show Path and QOS Details ",
    hourlyUsers: "Hourly active clients in the last 30 days",
    avgQuality:
      "Average quality observed on the default load balancing in the past 30 days",
    forcastQuality: "Forcasted quality if all the recommendations are applied",
    readyLabel: "Ready",
    noRecommActionsLabel: "No recommendation actions",
    sidePanelLossLabel: "Loss (%)",
    sidePanelLatencyLabel: "Latency (ms)",
    sidePanelJitterLabel: "Jitter (ms)",
    meanQuality: "Mean quality (%)",
    pathMetricsLabel: "Path metrics"
  },

  unknownSiteAvailTtip:
    "Availability information is not available for sites which are administratively down",

  // circuits
  circuit: {
    title: "Circuits",
    totalUsage: "Total usage",
    unknownAvailTtip:
      "Availability information is not available for circuits which are administratively down",

    // availability
    aTitle: "Total uptime",
    aUptimeSuffix: "% Uptime",

    // bandwidth
    bTitle: "Bandwidth",
    bYLabel: "Bandwidth Rx + Tx",

    // dia vs tunnel usage donut chart
    ddcTitle: "DIA vs Tunnel usage",
    diaText: "Direct Internet Access",
    ddcDia: "DIA",
    tunnel: "Tunnel",

    // color usage donut chart
    cdcTitle: "Color usage",

    // circuits table
    ctCountText: " circuits",
    ctSelectCountText: " selections",
    ctName: "Circuit name",
    ctProvider: "Service provider",
    ctSites: "Sites",
    ctLocation: "Site location",
    ctSiteId: "Site ID",
    ctAvail: "Availability",
    ctDowntime: "Downtime",
    ctRxTx: "RX + TX",
    ctRx: "RX",
    ctTx: "TX",
    ctRxUtil: "RX utilization (%)",
    ctTxUtil: "TX utilization (%)",
    ctLocalDevice: "Local device",
    ctLocalColor: "Local color",
    ctLoss: "Packet loss (%)",
    ctLatency: "Latency (ms)",
    ctJitter: "Jitter (ms)",
    ctTotalUsage: "Total app usage",
    ctTunnelUsage: "App usage tunnel",
    ctDIAUsage: "App usage DIA",
    ctAppCount: "Apps",
    ctTunnelCount: "Tunnels",
    ctVRFCount: "VRFs",

    // sidebar
    // availability
    aSBTitle: "Circuits trend by availability",

    // circuits table
    circuitTableTitle: "Select a circuit from table below to show trend by availability",
    ctSBTop: "Bottom 10 circuits",
    bDefinition:
      "To view Rx & Tx utilization %, define link's upstream & downstream bandwidth capacity on SD-WAN Manager (require version 20.12+)",
    rxTx: "Rx + Tx",
    rxUtilization: "Rx utilization (%)",
    txUtilization: "Tx utilization (%)"
  },

  // circuit 360
  c360: {
    // bandwidth chart
    rcTitle: "Bandwidth",
    rcXLabel: "Bandwidth",
    rcTtipTitle: "Bandwidth",
    rcTtipRxTx: "RX + TX",
    rcTtipRx: "RX",
    rcTtipTx: "TX",
    bwUtilization: "Utilization",

    // tunnels usage chart
    tcTitle: "Top 5 tunnels usage",
    tcTtipTitle: "Tunnels usage",

    // app usage chart
    ucTitle: "Top 5 applications usage",
    ucYLabel: "Usage",
    ucTtipTitle: "App usage",

    // tabs
    tabApp: "Applications",
    tabFlow: "Flows",
    tSearch: "Search",
    tEmpty: "No data available",

    // application table
    atTitle: "Applications",
    atApp: "Application name",
    atQoE: "QoE",
    atFamily: "App family",
    atUsage: "Usage",
    atLoss: "Packet loss (%) ",
    atLatency: "Latency (ms)",
    atJitter: "Jitter (ms)",

    // tunnel table
    ttTitle: "Tunnels",
    ttLocalIP: "Host local",
    ttRemoteIP: "Host remote",
    ttLocalColor: "Color local",
    ttRemoteColor: "Color remote",

    // flow table
    ftTitle: "Flows",
    ftDate: "Entry date",
    ftApp: "Application name",
    ftSIp: "Source IP",
    ftDIp: "Destination IP",
    ftSPort: "Source port",
    ftDPort: "Destination port",
    ftTName: "Tunnel name",
    ftPcol: "Protocol",
    ftUsage: "Usage"
  },

  defaultPathQualtyLabel: `<div>Current path</div><div>quality</div>`,
  recoPathQualityLabel: `<div>Recommended</div><div>path quality</div>`,
  defaultPathQualtyMapLabel: "Current path quality",
  recoPathQualityMapLabel: "Recommended path quality",
  // app360 Sankey chart
  groupTitleLocal: "Local",
  groupTitleRemote: "Remote",
  sankeyHeadingSite: "Site",
  sankeyHeadingColor: "Color",
  sankeyHeadingQoE: "QoE",

  // Tooltip Text
  preSpace: "\xa0",
  postSpace: "\u00A0",

  // Predictive Networks or Insights
  predictiveNetworks: {
    topTunnelsUsed: "Top Used Tunnels",
    allTunnelsUsed: "All Used Tunnels",
    topTunnel: "topTunnel",
    kpi: "KPI",
    tunnelName: "Tunnel Name",
    anomalyCount: "Anomaly Count",
    applicationCount: "Application Count",
    applicationUsageInBytes: "Usage (Bytes)",
    applicationUsage: "Usage",
    kpiDetails: "KPI details",
    remoteSite: "Remote Site",
    remoteLocation: "Remote Location",
    localSite: "Local Site",
    localLocation: "Local Location",
    predictivePathTitle: "Predictive Path Recommendations",
    predictiveNetworks: "Predictive networks",
    predictiveNetworksPageTitle: "Predictive Networks",
    siteInfoPageTitle: "Site information",
    deviceInfoPageTitle: "Device Pair Details",
    poweredByLabel: "Powered by",
    thousandEyesText: "Thousand Eyes",
    wanInsightsLabel: "WAN Insights",
    pathRecommendationText:
      "The Predictive Path Recommendations powered by ThousandEyes WAN Insights analyzes historical data on application traffic flows across various network paths, forecasts future network connectivity issues, and makes recommendations for using alternate path(s) if appropriate. You can leverage these recommendations to adjust your SD-WAN policies to improve application experience for your users.",
    clickActionText:
      "By clicking the “Activate” button you understand and agree that your SD-WAN analytics data will be shared with Cisco ThousandEyes WAN Insights to provide you with the Predictive Path Recommendations.",
    processingTextBefore: "Please see the ",
    processingLinkText: "ThousandEyes Privacy Data Sheet ",
    processingLink:
      "https://trustportal.cisco.com/c/r/ctp/trust-portal.html?doctype=Privacy%20Data%20Sheet|Privacy%20Data%20Map&search_keyword=ThousandEyes#/1610384661419206",
    processingTextAfter:
      " for details regarding processing of personal data by ThousandEyes WAN Insights.",
    activationText:
      "Depending on the size of your network, the activation may take up to several hours or days. Upon activation, the system will begin learning and analyzing from the historical data to make recommendations.",
    contactAdminText:
      "Contact your admin to find out how to activate this feature",
    openTACText: "Please open a Cisco TAC case to resolve this issue",
    activationIncomplete: "Activation Incomplete",
    activate: "Activate",
    retryActivate: "Retry Activation",
    activationInprogress: "Activation Requested.",
    inprogress: "Activation In progress",
    lincenseCheckInprogress: "License check in progress.",
    lincenseCheckComplete: "License check complete.",
    dataOnboardingInprogress: " Data onboarding is now in progress....",
    dataOnboardingDurationText:
      "Depending the size of the network, the activation can take up to 48 hours. You will receive an e-mail from Cisco/ThousandEyes informing about the progress and once onboarding is complete.",
    dataOnboardingInfoText:
      "After onboarding, this vAnalytics screen will refresh to begin showing insights and path recommendations. In addition to vAnalytics, you'll be able to access these insights from the ThousandEyes platform.",
    invalidEmail:
      "We were not able to locate an e-mail address (such as your Cisco CCO id) necessary to begin data handling by ThousandEyes WAN Insights.",
    pprTab: "Predictive Path Recommendations",
    bandForecastingTab: "Bandwidth Forecasting",
    TimestampSelected: "Timestamp Selected: ",
    anomaly: "Anomaly Detection",
    noAnomaly: "No Anomaly",
    noKpi: "No KPI Summary",
    trendinganomalychart: "Trending Anomaly Chart",
    anomalyAndUsageTrend: "Anomaly and Usage Trend",
    anomalySummaryLabel: "Anomaly Summary",
    infoMessage: "Anomaly Summary and Tunnel Information",
    anomalyKPILabel: "KPI",
    anomalyKPIDetailLabel: "KPI Details",
    anomalyOneHourLabel: "Anomalies detected in 1h",
    anomalySitesLabel: "# of Sites",
    anomalyRate: "Anomaly Rate(%)",
    anomalyBarClick: "Click above bar to select hourly Anomaly Count",
    anomalyClientsLabel: "# of Clients",
    anomalyTotalTunnelsLabel: "# Total Tunnels",
    anomalyTunnelsLabel: "# of Impacted Tunnels",
    poweredBy: "Powered by ",
    thousands: "Thousands",
    eyes: "Eyes ",
    wanInsights: "WAN Insights",
    anomalyCounts: "Anomaly rate(%)",
    anomalies: "Anomalies",
    recommendationReady: "Recommendation: Ready",
    recommendationNone: "None",
    recommendationSummary: "Recommendation summary",
    recommendedActions: "Recommended actions",
    viewDetails: "View Details",
    allRecommendations: "All recommended actions",
    pathQuality: "PATH QUALITY",
    defaultPath: "Current path",
    recomPath: "Recommended path",
    impactedUsers: "Impacted clients",
    currentPath: "Current path quality",
    recomPathQuality: "Recommended path quality",
    recomSummaryIconText: `You can monitor up to six applications/groups per Fabric with your embedded ThousandEyes WAN Insights entitlement against your DNA Advantage license.
                          You may notice initially that predictive path monitoring is enabled for greater than six applications/groups.
                          In future, such monitoring will be restricted to max of six applications/groups with your default entitlement.`,
    appTableConfigAppsLabel: "Application",
    appTableConfigSiteLabel: "Site",
    appTableConfigRxUtilzLabel: "RX Utilization (%)",
    appTableConfigTxUtilzLabel: "TX Utilization (%)",
    appTableConfigTotalUsageLabel: "Total App Usage",
    appTableConfigSiteLocationLabel: "Site Location",
    appTableConfigServiceProviderLabel: "Service Provider",
    appTableConfigCircNameLabel: "Circuit Name",
    appTableConfigSiteIdLabel: "Site ID",
    appTableConfigLocationLabel: "Location",
    appTableConfigActiveUsersLabel: "Active Clients",
    appTableConfigCurPathLabel: "Current path",
    appTableConfigRecomPathLabel: "Recommended path",
    appTableConfigCurPathQualityLabel: `<div>Current path</div> <div>quality</div>`,
    appTableConfigRecomPathQualityLabel: `<div>Recommended</div> <div>path quality</div>`,
    appTableConfigGainsLabel: "% Gain",
    appTableConfigDateLabel: "Date",
    appTableConfigStatusLabel: "Status",
    appTableConfigActionLabel: "Action",
    recomActionAppGroupLabel: "Sort by App Groups",
    recomActionSortGainLabel: "Sort by Gain %",
    recomActionSortDateLabel: "Sort by Date",
    recomActionSitesLabel: "Sort by Sites",
    heroBarSiteLabel: "Site",
    heroBarRecomdationLabel: "RECOMMENDATION",
    heroBarSiteLevelLabel: "SITE LEVEL RECOMMENDATION",
    heroBarSiteSemiLabel: "Site:",
    heroBarLocationLabel: "Location:",
    heroBarAppsLabel: "APPLICATIONS",
    heroBarCurPath: "Current path quality",
    heroBarRecomPath: "Recommended path quality",
    heroBarImactedUserLabel: "Impacted clients",
    heroBarDevicePair: "Device Pair Details",
    networkQoeMetricChartNoData: "No Data",
    actionRecomSearchHintLabel: "Search site, location",
    applyPredictive: "Apply predictive path recommendations",
    revertPredictive: "Revert predictive path recommendations",
    modalSubtitle:
      "Confirm the selected predictive path recommendations below before proceeding to preview and deployment of active policy.",
    proceedApply: "Proceed to apply",
    proceedRevert: "Proceed to revert",
    cancel: "Cancel",
    close: "Close",
    recomActions: "Recommended actions",
    applyrevert: "Apply/Revert",
    ready: "Ready",
    appInfoLabel: "Application information",
    recommendation: "Recommendation",
    topUsedTunnelsTitle: "Top Used Tunnels:",
    allUsedTunnelsTitle: "All Used Tunnels:",
    topUsedTunnels: "Displays anomalies on the top tunnels contributing upto 80% of application usage in the past week, capped at a maximum of 100 tunnels.",
    allUsedTunnels: "Displays anomalies on all tunnels used during the past week.",
    anomalyTableInfo: "Displays how many tunnels were scanned in the selected workflow, out of total tunnels in the entire network."
  },
  bandwidthForecast: {
    chartsUnits: "Total bandwidth usage",
    currentBand: "Current Bandwidth",
    forecastBand: "Forecasted Bandwidth",
    viewCircuitLabel: "View by Interface Name: ",
    yValueLabel: "Bandwidth (Rx+Tx)",
    minMax: "Min-Max Forecast",
    noForecast: "Not enough data to show forecast",
    noForecastCircuit: "The forecast for the selected circuit doesn't exist",
    circuitTTitle: "Circuit list",
    noCircuits: "No circuits available"
  },
  //Security tab (SD-WAN view)
  securityTabView: {
    noSitesOnMap: "No sites available on the map",
    noThreatsAccessedSiteOnMap: "No untrusted IP address ranges were accessed by the SD-WAN network",
    noThreatsGlobalSiteOnMap: "No untrusted IP address ranges were discovered by Talos Threat Intelligence.",
    selectedSite: "Selected site",
    selectedCluster: "Selected cluster",
    threatAccessSummary: "SD-WAN threat access summary",
    threatIPRange: "Selected thread IP range",
    sideBarUntrusted: "Untrusted IP(s): ",
    siteID: "Site ID",
    site: "Site",
    last30Days: "Last 30 days",
    threatSourceCount: "Client IP count",
    uniqueSourceCount: "Unique client IP count",
    siteLocation: "Site location",
    untrustedIPS: "Untrusted IP(s)",
    tableIPS: "IP(s)",
    sites: "Sites",
    untrustedIPSToolTip: "The count of unique low reputation IP address ranges that are detected as untrusted by Talos in the network",
    eventCount: "Event count",
    eventCountTooltip: "Number of times the untrusted IP range was accessed in the selected time period",
    untrustedIP: "Untrusted IP",
    threatCountry: "Origin",
    threatCountryToolTip: "Country where the untrusted IP is hosted",
    lastAccessed: "Last event (UTC)",
    sourceIPToolTip: "IP address of the client accessing the untrusted IP(s)",
    sourceIP: "Client IP",
    clientImpactedOn: "Date selected: ",
    exportTitleSites: "Sites untrusted access ",
    exportTitleUntrusted: "Untrusted IP(s) ",
    timeInUTC: "in UTC time",
    UTC: "UTC",
    exportTitleClientIp: "Client IP(s) ",
    sdwan: "Network",
    talos: "Global",
    threatsOverview: "Threat Overview",
    all: "All Threats",
    threatsAccessed: "Threats Accessed",
    talosThreatAccessSummary: "Threat access summary",
    talosInfoAllThreats: "All Threats: Displays a geographical distribution of untrusted IP address ranges discovered by Talos Threat Intelligence, aggregated by country of origin.",
    talosInfoThreatsAccessed: "Threats Accessed: Displays a geographical distribution of untrusted IP address ranges that were accessed by the SD-WAN network."
  },
  logs: {
    alarms: "Alarms",
    acl: "ACL Logs",
    events: "Events",
    audit: "Audit Logs",
    traffic: "Traffic Logs",
    setCriteria: "Set criteria to get events",
    getLogs: "Get Logs",
    cancel: "Cancel",
    label: "Logs",
    selectedDevices: "Select device(s)",
    timeOccurrence: "Time of occurrences",
    hostname: "Hostname",
    deviceSystem: "Device system IP",
    sourceIP: "Source IP",
    destinationIP: "Destination IP",
    sourcePort: "Source port",
    destinationPort: "Destination port",
    protocol: "Protocol",
    appDetails: "Application name",
    username: "Username/SGT",
    ruleName: "Rule name",
    policyName: "FW Policy name",
    action: "FW Action",
    extraTime: "It may take couple of minutes to load the records.",
    export: "Export",
    toastLoader: "Downloading is in progress. It may take couple of minutes to download the file.",
    cancelRequest: "Do you want to cancel the request?",
    ok: "OK",
    noMatch: "No matching records found.",
    pageTooltip: "The pagination is limited to 20 pages. Search any columns to get more specific results.",
    sitesInput: "Select site(s) name",
    emptyData: "No data available",
    fetchDevices: "Fetching device(s)...",
    fetchSites: "Fetching site(s)...",
    timeRange: "Select time range",
    sitesNotFound: "No sites found",
    vpnId: "VPN ID",
    siteName: "Site Name",
    records: "Records",
  }
};
